@import "src/assets/styles/sass-imports";

.hero,
.verticalHero {
  display: inline-block;
  font-family: "Judson", sans-serif;
  font-size: 60px;
  font-weight: normal;
  color: $blue;
}

.verticalHero {
  width: 50px;
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  rotate: 180deg;
  line-height: 0;
  color: $gray;
}

.subhero {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: $blue;
}

.title {
  display: inline-block;
  font-family: "Judson", sans-serif;
  font-size: 45px;
  font-weight: normal;
  color: $blue;
  word-break: break-word;
  hyphens: auto;
}

.text {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: $black;
}

.font-judson {
  font-family: "Judson", sans-serif;
  font-size: 22px;
}

.font-lato {
  font-family: "Lato", sans-serif;
  font-size: 18px;
}

.color-blue {
  color: $blue !important;
}

.color-black {
  color: $black !important;
}

.color-white {
  color: $white !important;
}

.color-gray {
  color: $gray !important;
}

.color-error {
  color: $error !important;
}

.bold {
  font-weight: bold !important;
}

@media (max-width: 767.98px) {
  .hero { font-size: 50px }
  .verticalHero { font-size: 50px }
  .subhero { font-size: 25px }
  .title { font-size: 35px }
}

@media (max-width: 575.98px) {
  .hero { font-size: 40px }
  .verticalHero { font-size: 40px }
  .subhero { font-size: 20px }
  .title { font-size: 30px }
}

@media (max-width: 399.98px) {
  .hero { font-size: 30px }
  .verticalHero { font-size: 30px }
  .subhero { font-size: 20px }
  .title { font-size: 25px }
}
