@import "src/assets/styles/sass-imports";

.navbar {
  background-color: $navbar-bg-color;

  @include tablet {
    height: $navbar-height-desktop;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbarToggle {
  border: none;

  .navbar-toggler-icon {
    fill: red;
  }
}
