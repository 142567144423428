@import "src/assets/styles/sass-imports";

.button {
  position: relative;
  padding: 0;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  background-color: transparent;
  color: $blue;
}

.lottie {
  display: none;

  @include tablet {
    display: inherit;
    position: absolute;
    pointer-events: none;
    top: -10px;
    bottom: -14px;
    left: -10px;
    right: -10px;
  }
}
