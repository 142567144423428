@import "src/assets/styles/sass-imports";

.content {
  position: relative;
  padding-top: $navbar-height-mobile;

  @include tablet {
    padding-top: $navbar-height-desktop;
  }
}
