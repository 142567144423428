@import "variables";
@import "fonts";
@import "sass-imports";

html {
  scroll-behavior: unset !important;
}

body {
  font-family: "Lato", sans-serif;
  background-color: $beige;
}
