@import "src/assets/styles/sass-imports";

.content {
  display: flex;
  flex-direction: column;
}

.titleCol {
  display: none;

  @include desktop {
    display: block;
  }
}
