@font-face {
  font-family: "Judson";
  src: local("Judson"), url("../fonts/judson/Judson-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Judson";
  src: local("Judson"), url("../fonts/judson/Judson-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Judson";
  src: local("Judson"), url("../fonts/judson/Judson-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}
