@import "src/assets/styles/sass-imports";

.header {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$navbar-height-mobile});

  @include tablet {
    height: calc(100vh - #{$navbar-height-desktop});
  }

  .artworkImg {
    width: 100%;
    max-width: 688px;
    margin: 0 auto;

    @include desktop {
      margin-left: auto;
      margin-right: 4em;
    }
  }

  .heroText {
    margin-top: -18px;
  }

  .lottieArrow {
    width: 60px;
    height: 60px;
    margin: auto auto 0 auto;
    cursor: pointer;
  }
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailLink {
  text-decoration: underline !important;
  color: $blue !important;

  &:hover {
    color: $blue !important;
  }
}

.socialContainer {
  display: flex;
}

.quoteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lottieSocial {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.divider {
  width: 250px;
  height: 1px;
  background-color: $blue;
}

.authorName {
  font-size: 16px;
  letter-spacing: 0.1rem;
}
