@import "src/assets/styles/sass-imports";

.projectCol:not(:last-child) {
  margin-bottom: 64px;
}

@include desktop {
  // Left columns
  .projectCol:nth-child(2n + 1) {
    padding-right: 1.5rem;
  }

  // Right columns
  .projectCol:nth-child(2n) {
    margin-top: 180px;
    padding-left: 1.5rem;
  }
}
