$blue: #0035DC;
$beige: #FFFBF5;
$gray: #DCD3C5;
$black: #000000;
$white: #FFFFFF;
$error: #DC3545;
$navbar-bg-color: #FFFBF5E6;

$navbar-height-mobile: 71px;
$navbar-height-desktop: 87px;

$breakpoint-xs: 0,;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin mobile {
  @media (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin mobileLarge {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
