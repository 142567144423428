@import "src/assets/styles/sass-imports";

.articleContainer {
  position: relative;
}

.articleBackground {
  position: absolute;
  top: 400px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  @include mobileLarge {
    top: 600px;
  }
}

.image {
  width: 100%;
  margin: 0 auto;
}

.projectSection {
  background-color: $beige;
}

.categoryText {
  font-size: 16px;
  letter-spacing: 0.1rem;
}
