.container {
  height: 100vh;
  max-width: 688px !important;
}

.artworkImg {
  width: 100%;
  max-width: 688px;
  margin: 0 auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
