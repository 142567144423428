@import "src/assets/styles/sass-imports";

.linkContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.link {
  display: inline-block;
  font-family: 'Judson', sans-serif;
  font-size: 22px;
  color: $blue;
}

.lottie {
  display: none;

  @include tablet {
    display: inherit;
    position: absolute;
    pointer-events: none;
    top: -10px;
    bottom: -14px;
    left: -10px;
    right: -10px;
  }
}

.iconRight,
.iconLeft,
.iconExternal {
  margin-top: -3px;
}

.iconRight {
  margin-left: 8px;
}

.iconLeft {
  margin-right: 8px;
  rotate: 180deg;
}

.iconExternal {
  margin-left: 8px;
  rotate: -45deg;
}

.underline {
  text-decoration: underline;
}

.color-blue {
  color: $blue;
}

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}
