@import "src/assets/styles/sass-imports";

.footer {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $blue;
}

.infoContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.divider {
  margin: 0 16px;
}

.copyright,
.infoContainer > * {
  opacity: 0.5;
  color: $white;
}
