.container {
  cursor: pointer;
}

.listItemImgContainer {
  overflow: hidden;
}

.listItemImg {
  width: 100%;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.categoryText {
  font-size: 16px;
  letter-spacing: 0.1rem;
}
